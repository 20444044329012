body.template-place-single{
    .page-container{
        h1.adaptive-bg{
            div.edit-links{
                position: relative;
                z-index: 10;
                text-transform: none;
                letter-spacing: 0.01em;
            }
            .dates, .place-address{
                font-size: 65%;
                font-weight: normal;
                margin:10px auto;
            }
            .place-address{
                font-size: 40%;
                text-transform: none;
                letter-spacing: 0.015em;
            }
        }

         div.edit-links{
            text-align: center;

            >p{
                margin:25px auto;
                padding:15px;
                border:1px dotted #fd8672;
                a{
                    text-decoration: none;
                    color:#fd8672;
                }
            }
        }   
    }
}