body > .site-header{
	background-repeat: repeat;
	background:none;
	border:none;
	z-index: 10;
	a.navbar-brand{
		opacity: 0.85;
		// background:#f7f7f8;
		&:hover{
			opacity:1;
		}
		transition: opacity 0.2s ease-in-out;
		 > img{
			height:38px;
			width:auto;
			margin-top:-10px;
			margin-left:15px;
		}
		display:block;
		float:left;
	}

	.has-bg{
		background-image:url(../images/navbar-bg.png);
	}
	>.container-fluid{
		padding:10px;
		padding-bottom:5px;
		border-radius:0 0 5px 5px;
		box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.25);
	}
	max-width: 1150px;
	margin-left:auto;
	margin-right:auto;

	&:before, &:after{
		position: absolute;
		bottom:0;
		width:16px;
		height:15px;
		background-repeat:none;
		display:block;
		content:"";
	}
	&:before{
		left:0;
		background-image:url(../images/navbar-left-corner.png);
		background-position: bottom left;
	}

	&:after{
		right:0;
		background-image:url(../images/navbar-right-corner.png);
		background-position: bottom right;
	}

	ul.nav > li{
		background:none;
		a{
			text-transform: uppercase;
			font-family: $f-wask;
			font-weight: bold;
			color:#2d2d30;
			background-color:transparent;
			font-size: 110%;
			letter-spacing: 0.05em;
			opacity:0.5;
			transition:all 0.2s ease-in-out;
			display:inline-block;
			position:relative;
			&:after{
				position:absolute;
				left:50%;
				right:50%;
				bottom:-5px;
				height:3px;
				background:#cbd0ed;
				display:block;
				content:"";
				opacity:0;
				transition:all 0.2s ease-in-out;
			}
			&:hover{

				&:after{
					opacity:1;
					left:15px;
					right:15px;

				}
			}
			&.btn-add-address{
				&:after{
					display:none;
				}
				>span{
					display:inline-block;
					padding:12px;
					vertical-align: middle;
					margin-left:10px;
					border-radius: 100%;
					border:1px solid #CCC;
					background:#FFF;
					position:relative;
					&:after{
						position:absolute;
						left:0;
						right:0;
						text-align: center;
						top:50%;
						transform:translateY(-50%);
						content:"+";
						font-size: 120%;
						display:inline-block;
					}
				}
				&:hover{
					color:#fd8672;
				}
			}
		}
		&.active > a{
			opacity:1;
			background-color:transparent;
			&:hover{
				background-color:transparent;
			}
		}

	}

}