.map-container{
	position: fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	.map{
		height:100%;
		width:100%; 
		&:after{
			opacity:0;
			pointer-events:none;
			background:#111715;
			position:absolute;
			top:0;
			left:0;
			bottom:0;
			right:0;
			content:"";
			display:block;
			transition:all 0.2s ease-in-out;
		}
		&.loading:after{
			opacity:0.3;
			pointer-events:all;
		}
	}
}

.place-popup-backdrop{
	position:fixed;
	left:0;
	right:0;
	bottom:0;
	top:0;
	background:rgba(0,0,0,0.1);
	z-index:10;
	pointer-events:none;
	opacity:0;
	transition:all 0.2s ease-in-out;
	&.active{
		pointer-events:all;
		opacity:1;

	}
}
.place-popup{
	a.btn-favorite{
		position:absolute;
		top:15px;
		left:30px;
		font-size: 2rem;
		transition: all 0.2s ease-in-out;
		&.disabled{
			color:#ccc;
		}
		&.on{
			color:#fd8672;
		}
	}
	width:90%;
	height:75%;
	max-height: 615px;
	max-width: 840px;
	position:fixed;
	top:50%;
	left:50%;
	margin-top:40px;
	z-index: 15;
	transform:translateY(-50%) translateX(-50%);
	>.popup-content{
		border-radius: 5px 0 0 5px;
		background:#FFF;
		width:50%;
		position:absolute;
		top:-30px;
		bottom:30px;
		left:25%;
		padding:5%;
		transition:all 0.5s ease-in-out;
		@media(max-width: $screen-sm){
			width:100%;
			left:0;
		}

		a.btn-close{
			position:absolute;
			display:block;
			top:15px;
			right:30px;
			color:#bcbdc0;
			text-decoration: none;
			font-size: 150%;
			transition: all 0.2s ease-in-out;
			cursor:pointer;
			&:hover{
				color:#000;
			}
		}

		h2{
			font-family: $f-accolade;
			font-weight: lighter;
			color:#2d2d30;
			line-height: 1.3;
			margin:3% 0 5% 0;
		}
		h3{
			font-family: $f-wask;
			font-weight: bold;
			letter-spacing: 0.1em;
			color:#a6a9b6;
			margin-bottom:0;
		}
		&:after{
			position:absolute;
			right:-20px;
			width:20px;
			top:0;
			bottom:-30px;
			background-image:url(../images/map.place.separator.png);
			background-size: 100% 100%;
			content:"";
			display:block;
		}
		.place-description{
			font-family: $f-lato;
			color:#494c59;
			max-height: 250px;
			overflow: auto;
		}
		a.btn-primary{
			display:block;
			margin:20px auto;
			border-radius:10px;
			background:#fafafa;
			color:#fd8672;
			font-family: $f-wask;
			text-transform:uppercase;
			text-align: center;
			font-weight: bold;
			font-size: 110%;
			padding:10px 15px;
			letter-spacing: 0.1em;
			transition:all 0.2s ease-in-out;
			opacity:0.8;
			&:hover{
				opacity:1;
			}
			border:none;
			max-width: 200px;
			position:relative;;
			&:after{
				position:absolute;
				right:15px;
				top:50%;
				transform:translateY(-50%);
				content:'+';
				display:block;
			}
		}
	}

	>.streetview{
		position:absolute;
		top:0;
		bottom:0;
		left:50%;
		margin-left:20px;
		right:0;
		border-radius: 0 5px 5px 0;
		background-image:url(../images/body-bg.jpg);
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		@media(max-width: $screen-sm){
			display:none;
		}
		opacity:0;
		// transition:opacity 0.5s ease-in-out;
	}
	&.has-panorama{
		>.streetview{
			opacity:1;
		}
		>.popup-content{
			left:0;
		}
	}
}

.place-tooltip{
	position: absolute;
	z-index: 10;
	background:#fff;
	border-radius:5px;
	padding:8px;
	max-width: 200px;
	transform:translateX(-50%);
	margin-top:5px;
	text-align: center;
	&:before, &:after{
		width: 0; 
		height: 0; 
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;

		border-bottom: 5px solid #fff;
		display: block;
		content:"";
		position: absolute;
		left:50%;
		margin-left:-3px;
		top:-4px;
	}
	border:1px solid #fd8672;
	&:before{
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-bottom: 6px solid #fd8672;
		margin-left:-4px;
		top:-6px;
	}
}