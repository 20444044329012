h1{
    font-family: $f-accolade;
}
.page-title {
    text-align: center;
    margin: 1rem auto;
    max-width: 750px;
}
.page-image {
    + .page-title {
        margin-top: 2rem;
    }
    &.adaptive-bg{
        padding:15% 5%;
        text-align: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        margin-top:0;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-weight: bold;
        position:relative;
        border-radius:0 0 5px 5px;
        >span{
            position:relative;
            z-index: 3;
        }
        &:before{
            position:absolute;
            left:0;
            right:0;
            top:0;
            bottom:0;
            background:#fff;
            opacity:0.25;
            display:block;
            content:"";
            z-index: 2;
        }
    }
}
.page-container {
	.page-content {
		font-size:2rem;
		max-width: 780px;
        margin: 0 auto;
        line-height: 1.5;
        * {
            line-height: 1.5;;
        }
        h2 {
            font-size:3rem;
        }
        h3 {
            font-size:2.5rem;
        }
        h4 {
            font-size: 2.25rem;
        }
	}
}