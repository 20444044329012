
// Fonts
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "components/header";
@import "components/map";
@import "components/explore";
@import "components/place";
@import "components/typography";
@import "components/fonts";

body{
	background-image:url(../images/body-bg.jpg);
	background-position: center center;
	background-size: cover;
}

a{
	color:#494c59;
	&:hover{
		color:#fd8672;
	}
}

div.loading-container{
	opacity:0;
	pointer-events:none;
	transition:opacity 0.2s ease-in-out;
	position: fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background-image:url(../images/loading-bg.jpg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color:black;

	&:after{
		position:fixed;
		top:50%;
		left:0%;
		right:0;
		height:100%;
		max-height: 596px;
		width:auto;
		content:"";
		display:block;
		background-position: center center;
		background-repeat: no-repeat;;
		background-size: contain;
		background-image: url(../images/loading-img.png);
		transform:translateY(-50%);
	}
}

body.loading div.loading-container{
	opacity:1;
}

body.template-{
	> .site-header{
		display:none;
	}
	>div.loading-container{
		opacity:1;
	}
}

.edit-address-map{
	width:auto;
	height:500px;
	max-height: 80vh;
}

