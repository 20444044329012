body.template-explorer{
    .explore-content{
        >.explore-list-content{
            position: relative;
            margin:0 auto;
            max-width: 760px;
        }
        p{
            text-align: center;
            margin:15px auto;
            a{
                color:#fd8672;
                text-decoration: none;
            }
        }
        .explore-list{
            display:inline-block;
            width:45%;
            &:first-child{
                float:left;
            }
            h2{
                font-weight: bold;
                font-family:  $f-wask;
                letter-spacing: 0.05em;
            }
            ul{
                padding:0;
                >li{
                    display:block;
                    float:left;
                    clear:both;
                    >a{
                        color:#494c59;
                        transition:all 0.2s ease-in-out;
                        padding:10px 0;
                        display:inline-block;
                        border-bottom:1px dotted #fd8672;
                        text-decoration: none;
                        &:hover{
                            color:#fd8672;
                        }
                    }
                    &:first-child > a{
                        border-top:1px dotted #fd8672;

                    }
                }
            }
            &:nth-child(2){
                float:right;
                text-align: right;
                ul > li{
                    float:right;
                }
            }
        }
    }   
}