
@font-face {
    font-family: 'accolade';
    src: url('../fonts/accolademedium-webfont.woff2') format('woff2'),
         url('../fonts/accolademedium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'accolade';
    src: url('../fonts/accoladebold-webfont.woff2') format('woff2'),
         url('../fonts/accoladebold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'accolade';
    src: url('../fonts/accolade-light-webfont.woff2') format('woff2'),
         url('../fonts/accolade-light-webfont.woff') format('woff');
    font-weight: lighter;
    font-style: normal;

}


@font-face {
    font-family: 'wasknew';
    src: url('../fonts/wask_new-webfont.eot');
    src: url('../fonts/wask_new-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/wask_new-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'wasknew';
    src: url('../fonts/wask_new_italic-webfont.eot');
    src: url('../fonts/wask_new_italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/wask_new_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: oblique;
}

@font-face {
    font-family: 'wasknew';
    src: url('../fonts/wask_new_bold-webfont.eot');
    src: url('../fonts/wask_new_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/wask_new_bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'wasknew';
    src: url('../fonts/wask_new_bold_italic-webfont.eot');
    src: url('../fonts/wask_new_bold_italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/wask_new_bold_italic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: oblique;
}
